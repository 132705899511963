
import { diagramToAnalyticsItem } from '@/utils/helpers'

export default {
  components: {
    'id-homepage-lazy-featured-single': () => import('~/components/homepage/lazy/FeaturedSingle.vue')
  },
  data: () => ({
    listName: 'Featured main page',
    diagrams: []
  }),
  fetchOnServer: false,
  async fetch () {
    this.diagrams = await this.$backend.featured_diagrams.list()
  },
  mounted () {
    this.$gtag.event(
      'view_item_list',
      {
        items: this.diagrams.map((diagram, index) => ({
          ...diagramToAnalyticsItem(diagram, index),
          list_name: this.listName
        }))
      }
    )
  }
}
